@import url(https://fonts.googleapis.com/css2?family=Sora&display=swap);
* {
  font-family: 'Sora', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* #styling{
    display: flex;
    justify-content: center;
    margin: auto;
    height: 90vmh;
    position: relative;
    top: 70px;

} */


button{
    background-color: rgb(175, 175, 175);
    border-radius: 3px;
    border: none;
    height: auto;
    width: auto;
    padding: 8px;
    font-size: 18px;
}

button:hover{
    background-color: rgb(145, 145, 145);
}

label{
    display: inline-block;
    width: 200px;
}

input{
    display: inline-block;
}

.signupinner{
    margin-bottom: 10px
}


table{
    border-collapse: collapse;
    margin: auto;

} 

td, tr, th {
    border: solid black;
    text-align: center;
    padding: 1vw;
    
}

button + button{
    margin-right: 2vw;
}

.test{
    display: block;
    justify-content: center;
    text-align: center;
}

h1{
    margin-Top: 50px;
    text-decoration: underline;
}

.navbar{
    margin: 0px;
    text-decoration: none;
}

